<template>
    <div class="login-overlay" v-if="!isLoggedIn">
      <div class="login-container">
        <h2>Insira suas credenciais</h2>
        <h6>Para acessar a aplicação, favor inserir as credenciais fornecidas pela IcaroTech.</h6> <br><br>
        <form @submit.prevent="handleLogin">
          <div class="input-group">
            <label for="username">Usuário:</label>
            <input v-model="username" id="username" type="text" required />
          </div>
          <div class="input-group">
            <label for="password">Senha:</label>
            <input v-model="password" id="password" type="password" required />
          </div>
          <button type="submit">Entrar</button>
        </form>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
        <br><br>
        <h6>Precisa de ajuda? Entre em contato em cs@icarotech.com</h6>
      </div>
    </div>
  </template>
  
  <script>
  import { encrypt } from "@/utils/encryption";
  import axios from "axios";
  
  export default {
    name: "LoginComponent",
    data() {
      return {
        username: "",
        password: "",
        errorMessage: "",
        isLoggedIn: false,
      };
    },
    methods: {
      async handleLogin() {
        // Encrypt the password before sending it to the backend
        const encryptedPassword = encrypt(this.password);
        const ip_server = window.location.hostname
        
  
        try {
          const response = await axios.post(`https://${ip_server}/api/login`, {
            username: this.username,
            password: encryptedPassword,
          });
  
          const data = response.data;
  
          if (data.access_token) {
            // Store the token in localStorage
            localStorage.setItem("access_token", data.access_token);
            this.isLoggedIn = true; // Set user as logged in
            this.$emit("logIn", data.access_token);
          } else {
            this.errorMessage = "Invalid login credentials";
          }
        } catch (error) {
          this.errorMessage = "Login failed. Please try again.";
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .login-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .login-container {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  .input-group {
    margin-bottom: 1rem;
  }
  
  input {
    width: 100%;
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  button {
    padding: 0.75rem 1.5rem;
    background-color: #00b2e5;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #00699e;
  }
  
  .error-message {
    color: red;
  }
  </style>
  