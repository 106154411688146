<template>
  <div id="app">
    <LoginComponent v-if="!isLoggedIn" @logIn="logIn"/>
    <div id="app-interface" v-else>
      <HeaderComponent />
      <BodyComponent />
      <FooterComponent />  
    </div>
  </div>
</template>

<script>
import HeaderComponent from "./components/AppHeader.vue";
import FooterComponent from "./components/AppFooter.vue";
import BodyComponent from "./components/BodyContainer.vue";
import LoginComponent from "./components/LoginComponent.vue";

export default {
  name: "App",
  components: {
    HeaderComponent,
    FooterComponent,
    BodyComponent,
    LoginComponent
  },

  data() {
    return {
      isLoggedIn: false,
    };
  },

  methods:  {
    logIn() {
      this.isLoggedIn = true;
    }
  }

/*  mounted() {
    // Check if user is logged in by validating token from localStorage
    const token = localStorage.getItem("token");
    if (token) {
      this.validateToken(token);
    }
  },
  methods: {
    async validateToken(token) {
      // Send token to backend to validate it
      const response = await fetch('/api/validate-token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ token }),
      });

      const data = await response.json();
      if (data.valid) {
        this.isLoggedIn = true;
      } else {
        localStorage.removeItem("token");
      }
    },
  },*/
};
</script>

<style>
/* Reset margins and padding */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden; /* Prevent scrollbars if there is no overflow */
  box-sizing: border-box;
}

/* Apply border-box to all elements */
*, *::before, *::after {
  box-sizing: border-box;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

#app-interface {
  height: 100vh;
}
</style>
