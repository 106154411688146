<template>
  <footer class="app-footer">
    <div class="container-fluid">
      <div class="row align-items-center">
        <!-- Logo à esquerda -->
        <div class="col-auto logo-container">
          <img :src="require('@/assets/white-logo_icaro_tech.png')" alt="Logo" class="logo">
        </div>
        <!-- Texto centralizado -->
        <div class="col text-center">
          <p>&copy; 2024 Chat Application</p>
        </div>
      </div>
    </div>
    
    
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style scoped>
.app-footer {
  height: 10%;
  background-color: #00b2e5;
  color: white;
  padding: 8px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.row {
  width: 100%;
  display: flex;
  align-items: center;
}

p{
  margin: 0;
}

.logo-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.logo {
  max-height: 50px;
}
</style>