<template>
    <div class="body-container">
        <BodySidebar @messageSent="handleUserMessage" />
        <div class="main-content">
            <BodyHeader />
            <ChatComponent :messages="messages" :isWaiting="isWaiting"/>
            <ChatInput ref="chatInput" @messageSent="handleUserMessage" :isWaiting="isWaiting" />
        </div>
    </div>
</template>
  
  <script>
  import BodySidebar from "./BodySidebar.vue";
  import BodyHeader from "./BodyHeader.vue";
  import ChatComponent from "./ChatInterface.vue";
  import ChatInput from "./ChatInput.vue";
  import axios from 'axios';
  
  export default {
    name: "BodyComponent",
    components: {
      BodySidebar,
      BodyHeader,
      ChatComponent,
      ChatInput,
    },
    data() {
      return {
        messages: [],
        isWaiting: false, // Track whether we are waiting for the bot's response
      };
    },
    methods: {
      async handleUserMessage(userMessage) {
        // 1. Add the user's message to the chat interface
        this.messages.push({ text: userMessage, sender: "user" });
  
        // 2. Disable user input while waiting for the bot's response
        this.isWaiting = true;
  
        // 3. Send the user message to the external API
        try {
          const botResponse = await this.sendMessageToApi(userMessage);
          var botResponseParse = botResponse.data.output;
          
          // 4. Capture and display the bot's response
          this.messages.push({ text: botResponseParse, sender: "bot" });
        } catch (error) {
          console.error("Error communicating with the API:", error);
          // Handle error (e.g., notify user of the issue)
        } finally {
          // 5. Re-enable user input after bot response is received
          this.isWaiting = false;
          // Delay no setfocus, para que o Vue habilite novamente o textarea
          setTimeout(() => {
            this.$refs.chatInput.focusTextArea();
          }, 300);
          
        }
      },
      
      // Simulate sending a message to an external API
      async sendMessageToApi(userMessage) {
        const token = localStorage.getItem('access_token');
        const ip_server = window.location.hostname
        
        try {
        const response = await axios.post(`https://${ip_server}/api/send-message`, {
          input: userMessage
        },
        {
          headers: {
              Authorization: `Bearer ${token}`  // Envia o token JWT no cabeçalho
            }
        });
        return response;
      } catch (error) {
        console.error('Erro ao enviar mensagem para a API:', error);
        alert(error.message)
        throw error; 
      }
      },
    },
  };
  </script>
  
  <style scoped>
  .body-container {
    display: flex;
    height: 80%;
  }
  
  .main-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .chat-window {
    flex-grow: 1;
    overflow-y: auto; /* Allow the chat window to take up remaining space */
  }
  
  .chat-input {
    border-top: 1px solid #ddd;
  }
  </style>
  