<template>
  <div class="chat-window" ref="chatWindow">
    <div v-for="(message, index) in messages" :key="index" :class="['chat-message', message.sender]">
      <span v-html="convertMarkdown(message.text)"></span>
    </div>
    <div v-if="isWaiting" class="loading-container">
        <img src="https://i.gifer.com/YlWC.gif" alt="Carregando..." width="70" />
      </div>
  </div>
</template>

<script>
import { marked } from 'marked';

export default {
  name: "ChatInterface",
  props: ["messages", "isWaiting"],
  updated() {
    // Scroll to bottom when the component is updated (i.e., new messages are added)
    this.scrollToBottom();
  },
  methods: {
    scrollToBottom() {
      const chatWindow = this.$refs.chatWindow;
      chatWindow.scrollTop = chatWindow.scrollHeight;
    },
    convertMarkdown(text) {
      return marked(text);
    }
  },
};
</script>

<style scoped>
.chat-window {
  flex-grow: 1;
  /* Allow the chat window to grow within the available space */
  overflow-y: auto;
  /* Allow scrolling when there are many messages */
  padding: 16px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.chat-message {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f1f1f1;
  border-radius: 4px;
}

.chat-message.user {
  background-color: #d4f4fa;
  /* Different color for user messages */
  text-align: right;
  /* Align user messages to the right */
}

.chat-message.bot {
  background-color: #f6f6f6;
  /* Default color for bot messages */
  text-align: left;
  /* Align bot messages to the left */
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>