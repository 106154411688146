<template>
    <div class="body-header">
    </div>
  </template>
  
  <script>
  export default {
    name: "BodyHeader",
  };
  </script>
  
  <style scoped>
  .body-header {
    padding: 8px;
    background-color: #f1f1f1;
    border-bottom: 1px solid #ddd;
  }
  </style>
  