<template>
  <div class="body-sidebar" :class="{ collapsed: isCollapsed }">
    <button ref="toggleButton" class="toggle-btn" @click="toggleSidebar" :class="{ buttoncollapsed: isCollapsed }">
      <!-- {{ isCollapsed ? '>>' : '<<' }}  -->
      <i class="bi bi-layout-sidebar-inset"></i>
    </button>
        <nav v-if="!isCollapsed">
          <div class="cards">
            <h6>Sugestões de Mensagens</h6>
            <div class="card">
              <div class="card-body" @click="sendMessage($event)">
                Quantos clientes tem sinistros em análise?
              </div>
            </div>
            <div class="card">
              <div class="card-body" @click="sendMessage($event)">
                Qual o valor médio pago em apólices que possuem sinistros negados?
              </div>
            </div>
            <div class="card">
              <div class="card-body" @click="sendMessage($event)">
                Qual o percentual de clientes que possuem mais de uma apólice?
              </div>
            </div>
            <div class="card">
              <div class="card-body" @click="sendMessage($event)">
                Qual é a distribuição de tipos de apólice entre os clientes?
              </div>
            </div>
            <div class="card">
              <div class="card-body" @click="sendMessage($event)">
                Qual é a porcentagem de sinistros aprovados por tipo de apólice?
              </div>
            </div>
          </div>
        </nav>
  </div>
</template>

<script>
export default {
  name: "BodySidebar",
  data() {
    return {
      isCollapsed: false,
    };
  },
  methods: {
    toggleSidebar() {
      this.isCollapsed = !this.isCollapsed;
      // teste mudança de css via JS
      var button = this.$refs.toggleButton;
      if (this.isCollapsed) {
        button.style.backgroundColor = "rgba(80, 80, 80, 0.782)" ;
      } else {
        button.style.backgroundColor = "#00b3e5a9";
      }
    },
    sendMessage(event) {

      const text = event.target.innerText;
      this.$emit("messageSent", text);
    }
  },
};
</script>

<style scoped>
/* Same styles as before */
.body-sidebar {
  width: 200px;
  background-color: #333;
  color: white;
  padding: 16px;
  overflow-y: auto;
  overflow-x: hidden;
}

.collapsed {
  width: 1px;
  background-color: transparent;
}

.buttoncollapsed{
  position: absolute;
  z-index: 999;
}

.toggle-btn {
  background-color: #00b3e5a9;
  border-radius: 5px;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.toggle-btn:active {
  transform: scale(0.90); 
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

nav ul {
  list-style: none;
  padding: 0;
}

nav ul li {
  margin-bottom: 10px;
}

nav ul li a {
  color: white;
  text-decoration: none;
}

.card {
  margin-bottom: 10px;
  font-size: small;
}

.cards {
  margin-top: 20px;
}

.card-body {
  cursor: pointer;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
}

.card-body:active {
  transform: scale(0.90); 
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}
</style>