const CryptoJS = require('crypto-js');
const cryptoKey = process.env.VUE_APP_CRYPTO_KEY;
const ivKey = process.env.VUE_APP_IV_KEY;

if (!cryptoKey || !ivKey) {
    throw new Error('CRYPTO_KEY and IV_KEY must be defined in the environment variables.');
}

// Ensure the secretKey is either 16, 24, or 32 bytes for AES-128, AES-192, or AES-256, respectively.
if (cryptoKey.length !== 16 && cryptoKey.length !== 24 && cryptoKey.length !== 32) {
    throw new Error('CRYPTO_KEY must be 16, 24, or 32 characters long for AES encryption.');
}

// Ensure the IV is 16 bytes (32 hex characters)
if (ivKey.length !== 32) {
    throw new Error('IV_KEY must be 32 hexadecimal characters long (16 bytes).');
}

const secretKey = CryptoJS.enc.Utf8.parse(cryptoKey);
const iv = CryptoJS.enc.Hex.parse(ivKey);


function encrypt(text) {
    const encrypted = CryptoJS.AES.encrypt(text, secretKey, { iv: iv }).toString();
    return encrypted;
}

export { encrypt };
