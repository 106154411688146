<template>
  <div class="chat-input">
    <textarea ref="textarea" 
    v-model="newMessage" 
    @keyup.enter="handleKeyUp" 
    @input="adjustHeight" 
    type="text"
    placeholder="Digite uma mensagem..." :disabled="isWaiting" />
    <button @click="sendMessage" :disabled="isWaiting"><i class="bi bi-send"></i></button>
  </div>
</template>

<script>
export default {
  name: "ChatInput",
  props: {
    isWaiting: {  // Declare the isWaiting prop here
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      newMessage: "",
      maxHeight: 300
    };
  },
  methods: {
    sendMessage() {
      if (this.newMessage.trim()) {
        this.$emit("messageSent", this.newMessage);
        this.newMessage = "";
        // Volta o TextArea para o Tamanho original
        this.resetTextareaHeight();
      }
    },
    adjustHeight() {
      const textarea = this.$refs.textarea;
      textarea.style.height = "auto"; 
      textarea.style.height = Math.min(textarea.scrollHeight, this.maxHeight) + "px"; 
    },
    resetTextareaHeight() {
      const textarea = this.$refs.textarea;
      textarea.style.height = "";
    },
    focusTextArea() {
      this.$refs.textarea.focus();
    },
    handleKeyUp(event) {
      if (event.key === "Enter" && !event.shiftKey) {
        this.sendMessage();
      }
    }
  },
};
</script>

<style scoped>
.chat-input {
  background-color: #fff;
  display: flex;
  gap: 10px;
  padding: 16px;
  border-top: 1px solid #ddd;
}

textarea {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 10px 16px;
  background-color: #00b2e5;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:disabled,
input:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button:hover:not(:disabled) {
  background-color: #00699e;
}
</style>