<template>
  <header class="app-header">
    <!-- <h3 class="text-white">Copilot Porto Seguro</h3>
    <div class="col-auto logo-container">
      <img src="logo-porto.png" alt="Logo" class="logo">
    </div> -->

    <div class="container-fluid">
      <div class="row align-items-center">
        <!-- Logo à esquerda -->
        <div class="col-auto logo-container">
          <img :src="require('@/assets/logo-porto.png')" alt="Logo" class="logo">
        </div>
        <!-- Texto centralizado -->
        <div class="col text-center">
          <h2 class="text-white">Copilot Porto Seguro</h2>
        </div>
      </div>
    </div>


  </header>
</template>

<script>
export default {
  name: "AppHeader",
};
</script>

<style scoped>
.app-header {
  height: 10%;
  background-color: #00b2e5;
  color: white;
  padding: 2px;
  font-size: 1rem;
  display: flex;
  align-items: center;
}

h3 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1rem;
}

.logo-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.logo {
  max-height: 75px;
}
</style>